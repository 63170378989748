<template>
<div class="card-body zero-padding d-flex">
  <apexchart
    class="customapexchart"
    :type="type"
    :options="chartOptions"
    :series="chartData"
    height="90%"
  ></apexchart>
  <div class ='chartsummary'>
    <div id="customtable">
      <b-table
        :outlined="true"
        :items="tableItems"
        :fields="fields">
          <template v-slot:[`cell(${fields[0]})`]="data">
            <div class= "d-flex justify-content-flex-start align-items-center">
              <span class="dot" :style="{backgroundColor: data.value.color}"></span>
              <span class="table-text">{{ $t(data.value.label) }} </span>
            </div>
          </template>
          <template v-slot:[`cell(${fields[1]})`]="data">
            <h3 class="table-number">{{ data.value}} </h3>
          </template>
      </b-table>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'StatusDetails',
  props:
    {
      type:
      {
        type: String
      },
      chartOptions:
        {
          type: Object
        },
      chartData:
        {
          type: Array
        },
      dataHeadings:
        {
          type: Array
        }
    },
  data () {
    return {
      fields: [],
      tableItems: {}
    }
  },
  methods: {
    loadTableItems () {
      this.fields = this.dataHeadings
      this.fields[0] = this.$t(this.fields[0].toLowerCase())
      this.fields[1] = this.$t(this.fields[1].toLowerCase())
      /* istanbul ignore next */
      this.tableItems = this.chartOptions.labels.map((label, i) => {
        const dataobj = {}
        dataobj[this.fields[0]] = { label: label, color: this.chartOptions.colors[i] }
        dataobj[this.fields[1]] = this.chartData[i]
        return dataobj
      })
    }
  },
  async mounted () {
    this.loadTableItems()
  }
}
</script>
<style>
.zero-padding{
  padding: 0px !important;
}
.customapexchart{
  flex: 1 !important;
}
.chartsummary {
  border-radius: 8px !important;
  flex: 1 !important;
  height: 250px !important;
  text-align: -webkit-right !important;
  padding: 70px 15px 0px 0px !important;
}
table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td,
.table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 10px 5px 10px 5px !important;
}
#customtable>.table>thead>tr>th, #customtable>.table>tbody>tr>th, #customtable>.table>tfoot>tr>th, #customtable>.table>thead>tr>td,
#customtable>.table>tbody>tr>td, #customtable>.table>tfoot>tr>td {
  padding: 10px !important;
  vertical-align: top !important;
  border-top: none !important;
}
#customtable table {
  background-color: var(--background-color-primary-light-dark) !important;
  color: var(--text-primary-color);
}
#customtable>.table>thead>tr>th {
  vertical-align: bottom !important;
  border-bottom: none !important;
}
#customtable > .border {
  border: 1px solid var(--very-light-input-border) !important;
}
.dot {
  height: 10px !important;
  width: 10px !important;
  border-radius: 50% !important;
  margin: 0 4px !important;
}
.table-text {
  color: var(--text-primary-color) !important;
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom:0 !important;
}
h3 {
  font-size : 14px !important;
}
.table-number {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif !important;
  margin-bottom:0 !important;
  color: var(--text-primary-color) !important;
  text-align: left !important;
}
.table{
   background-color:#F8F9FA !important;
   margin-bottom: 0px !important;
   border-radius: 8px !important;
   border-collapse: unset !important;
}
thead{
  font-size: 17px !important;
}
tbody{
  font-size: 14px !important;
}
</style>
