<template>
  <Loading id="charts-loader" :type="chartType" v-if="this.loading"/>
  <div v-else-if="chartData.length === 0 || chartData === null" class="h4 custom-empty-msg">No data available</div>
  <ChartSummary v-else-if="dataHeadings && dataHeadings.length" v-bind:type="chartType" v-bind:chartOptions="chartOptions" v-bind:chartData="chartData" v-bind:dataHeadings="dataHeadings"/>
    <apexchart
    v-else
    :type="chartType"
    :options="chartOptions"
    :series="chartData"
    height="90%"
  ></apexchart>
</template>

<script>
import { ChartMixin } from '../mixins/ChartMixin'
import Loading from '../components/Loading'
import ChartSummary from './ChartSummary.vue'
export default {
  mixins: [ChartMixin],
  name: 'ChartsUI',
  components: {
    Loading,
    ChartSummary
  },
  mounted: function () {
    if (this.chartType === 'area') {
      const children = this.$children
      setTimeout(() => {
        /* istanbul ignore next */
        children[0].zoomX(
          new Date(this.dataOptions.lastMonth.fromTimestamp).getTime(),
          new Date(this.dataOptions.lastMonth.toTimestamp).getTime()
        )
      }, 1000)
    }
  }
}
</script>
<style>
.apexcharts-legend.apexcharts-align-left.position-bottom{
  inset: 350px 0px 0px 0px;;
}
.apexcharts-text {
  fill: var(--text-primary-color) !important;
}
.apexcharts-legend {
  height: 80px !important;
  padding: 10px !important;
  display: block !important;
  line-height: 0px !important;
}
.apexcharts-legend-text {
  margin-right: 16px !important;
  color: var(--text-primary-color) !important;
}
.apexcharts-tooltip {
  background: #011738 !important;
  color: #fff !important;
}
.apexcharts-legend-series {
  margin: 0px !important;
}
.apexcharts-legend.position-bottom.apexcharts-align-left {
  height: 120px !important;
}
.apexcharts-legend.apexcharts-align-right .apexcharts-legend-series, .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
    display: inline-block !important;
    margin-bottom: 0px !important;
}
.custom-empty-msg {
    display: block !important;
    text-align: center !important;
    position: relative !important;
    top: 45%;
}
#charts-loader{
    height: 313px !important;
}
</style>
